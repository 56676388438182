import {
  AppointmentFeedback,
  AppointmentPage,
  AppointmentReference,
  IAppointment,
  IRitual,
  IUser,
  MatchingPage,
  MatchReference,
  MatchSource,
  MatchStatus,
  MentoringRole,
  ScheduleReference,
  TimeSlot,
  UserReference,
} from '@mentessa/types';
import { Action } from '@/store/types';

export const moduleName = 'matching';

export interface MatchingPair {
  left: IUser;
  right: IUser;
}

export interface MatchingState {
  matches: MatchingPage;
  appointments: AppointmentPage;
}

export enum MatchingActions {
  LoadMatches = '[Matching] Load All Matches',
  AddMatch = '[Matching] Add Match',
  BookSession = '[Matching] Book Session',
  RequestMatch = '[Matching] Request Match',
  UpdateAppointment = '[Matching] Update Appointment',
  GetBookedSessions = '[Matching] Get Booked sessions',
  ClearBookedSession = '[Matching] Clear Booked session',
  RandomizeSlots = '[Matching] Randomize Slots',
  ClearBookedSlots = '[Matching] Clear Booked Slots',
  ChangeMatchAcceptedStatus = '[Matching] Set Match Accepted Status',
  LoadMatchMentoringSessionRef = '[Matching] Load Match Mentoring Session Ref',
  SendAppointmentFeedback = '[Matching] Send Appointment Feedback',
  LoadPendingAppointments = '[Matching] Load Pending Appointments',
  LoadUserMatches = '[Matching] Load user matches',
  LoadPendingPatches = '[Matching] Load pending patches',
  ExportMatches = '[Matching] Export Matches',
}

export enum MatchingMutations {
  SetMatches = '[Matching] Set Matches',
  SetAppointments = '[Matching] Set Appointments',
  ChangeAppointment = '[Matching] Change Appointment',
}

export class ExportMatches implements Action {
  type = `${moduleName}/${MatchingActions.ExportMatches}`;

  constructor(private readonly ritual: IRitual) {}
}

export interface MatchesFilter {
  query?: string;
  source?: MatchSource;
  ritual?: number;
}

export interface MatchesOrder {
  sortBy?: Array<string>;
  sortOrder?: Array<'asc' | 'desc'>;
}

export class LoadMatches implements Action {
  type = `${moduleName}/${MatchingActions.LoadMatches}`;

  constructor(
    private readonly page: number = 1,
    private readonly filter: MatchesFilter,
    private readonly order?: MatchesOrder,
  ) {}
}

export class AddMatch implements Action {
  type = `${moduleName}/${MatchingActions.AddMatch}`;

  constructor(
    private readonly pairs: MatchingPair[],
    private readonly roles: { left: MentoringRole; right: MentoringRole },
    private readonly message: string,
    private readonly createSession: false,
  ) {}
}

export class BookSession implements Action {
  type = `${moduleName}/${MatchingActions.BookSession}`;

  constructor(
    private readonly targetRef: UserReference,
    private readonly scheduleRef: ScheduleReference,
    private readonly date: Date,
    private readonly message: string,
    private readonly slot?: TimeSlot,
    private readonly userRef?: UserReference, // ToDo: is weekday required here? // private readonly weekday: Weekday,
  ) {}
}

export class RequestMatch implements Action {
  type = `${moduleName}/${MatchingActions.RequestMatch}`;

  constructor(
    private readonly targetRef: UserReference,
    private readonly message: string,
    private readonly purpose: Array<string>,
    private readonly actor?: IUser,
  ) {}
}

export class ChangeMatchAcceptedStatus implements Action {
  type = `${moduleName}/${MatchingActions.ChangeMatchAcceptedStatus}`;

  constructor(
    private readonly matchRef: MatchReference,
    private readonly status: boolean,
    private readonly actor?: IUser,
  ) {}
}

export class AcceptMatch extends ChangeMatchAcceptedStatus {
  constructor(matchRef: MatchReference, actor?: IUser) {
    super(matchRef, true, actor);
  }
}

export class DeclineMatch extends ChangeMatchAcceptedStatus {
  constructor(matchRef: MatchReference, actor?: IUser) {
    super(matchRef, false, actor);
  }
}

export class LoadMatchMentoringSessionRef implements Action {
  type = `${moduleName}/${MatchingActions.LoadMatchMentoringSessionRef}`;

  constructor(private readonly matchRef: MatchReference, private readonly actor?: IUser) {}
}

export class GetBookedSessions implements Action {
  type = `${moduleName}/${MatchingActions.GetBookedSessions}`;

  constructor(private readonly user?: IUser) {}
}

export class ClearBookedSession implements Action {
  type = `${moduleName}/${MatchingActions.ClearBookedSession}`;

  constructor(private readonly user?: IUser, private readonly appointment?: IAppointment) {}
}

export class UpdateAppointment implements Action {
  type = `${moduleName}/${MatchingActions.UpdateAppointment}`;

  constructor(
    private readonly appointmentRef: AppointmentReference,
    private readonly dto: Partial<IAppointment>,
    private readonly userRef?: UserReference,
  ) {}
}

export class RandomizeSlots implements Action {
  type = `${moduleName}/${MatchingActions.RandomizeSlots}`;
}

export class ClearBookedSlots implements Action {
  type = `${moduleName}/${MatchingActions.ClearBookedSlots}`;
}

export class SendAppointmentFeedback implements Action {
  type = `${moduleName}/${MatchingActions.SendAppointmentFeedback}`;

  constructor(
    private readonly appointmentRef: AppointmentReference,
    private readonly feedback: AppointmentFeedback,
    private readonly userRef?: UserReference,
  ) {}
}

export class LoadPendingAppointments implements Action {
  type = `${moduleName}/${MatchingActions.LoadPendingAppointments}`;

  constructor(private readonly userRef?: UserReference) {}
}

export class LoadUserMatches implements Action {
  type = `${moduleName}/${MatchingActions.LoadUserMatches}`;

  constructor(private readonly status?: MatchStatus, private readonly userRef?: UserReference) {}
}

export class LoadPendingPatches implements Action {
  type = `${moduleName}/${MatchingActions.LoadPendingPatches}`;

  constructor(private readonly userRef?: UserReference) {}
}
