<template>
  <div class="d-flex flex-column m-dashboard-widget__col-3 m-dashboard-widget__row-2">
    <div class="d-flex justify-space-between">
      <h4>{{ $t('dashboard.widgets.ritual_monthly_feedbacks.title') }}</h4>
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-if="feedbacks.length" icon v-bind="attrs" @click="download" v-on="on">
            <v-icon>mdi-download</v-icon>
          </v-btn>
        </template>
        <span>{{ $t('dashboard.widgets.ritual_monthly_feedbacks.downloadTooltip') }}</span>
      </v-tooltip>
    </div>
    <div class="d-flex flex-row">
      <v-chip-group v-model="selectedRoles" multiple>
        <v-chip filter small> {{ $t('mentoring.role.mentee') }}</v-chip>
        <v-chip filter small> {{ $t('mentoring.role.mentor') }}</v-chip>
      </v-chip-group>
    </div>
    <div class="flex-grow-1 overflow-auto scroll-wrapper">
      <v-simple-table v-if="feedbacks.length > 0" fixed-header height="280px">
        <thead>
          <tr class="text-left valign-top">
            <th v-for="(header, index) in headers" :key="`header-${index}}`">
              {{ $t(`dashboard.widgets.ritual_monthly_feedbacks.table.header.${header}`) }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(feedback, index) in feedbacks" :key="`row-${index}`">
            <td v-for="(header, index) in headers" :key="`column-${index}}`">
              <span v-if="header === 'month'"> {{ feedback.attributes.month }} </span>
              <span v-else-if="header === 'user'"> <m-user-preview :user="feedback.user" /></span>
              <span v-else-if="header === 'role'">
                {{ feedback.role ? $t(`mentoring.role.${feedback.role}`) : '' }}
              </span>
              <span v-else>{{ getValue(feedback.answers[header]) }}</span>
            </td>
          </tr>
          <tr v-if="feedbacks.length > 0">
            <td v-for="(header, index) in headers" :key="`avg-${index}}`" :class="{ 'text-end': header === 'user' }">
              <span v-if="header === 'user'">
                {{ $t(`dashboard.widgets.ritual_monthly_feedbacks.table.avg`) }}
              </span>
              <span v-else-if="header !== 'month'">{{ average[header] }}</span>
            </td>
          </tr>
        </tbody>
      </v-simple-table>
      <div v-else class="d-flex justify-center align-center fill-height">
        {{ $t(`dashboard.no_data`) }}
      </div>
    </div>
  </div>
</template>
<style>
.scroll-wrapper {
  overflow-x: auto;
  overflow-y: hidden;
  padding-bottom: 16px; /* Ensure space for the scrollbar */
}
.scroll-wrapper::-webkit-scrollbar {
  height: 8px; /* Customize scrollbar height */
}
</style>
<script lang="ts">
import Vue, { PropType } from 'vue';
import {
  IUser,
  MentoringRole,
  MentoringSessionFeedbackAnswerAttributes,
  UserIdentityAttributes,
} from '@mentessa/types';
import MUserPreview from '@/components/MUserPreview/UserPreviewPure.vue';

export interface RitualMonthlyFeedbackRowBase {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  answers: Record<string, any>;
  attributes: MentoringSessionFeedbackAnswerAttributes;
  role: MentoringRole;
  feedbackName: string;
}

export interface RitualMonthlyFeedbackRowExternal extends RitualMonthlyFeedbackRowBase {
  userId: string;
  identity: UserIdentityAttributes;
}

export interface RitualMonthlyFeedbackRowInternal extends RitualMonthlyFeedbackRowBase {
  user: Partial<IUser>;
}

export interface RitualMonthlyFeedbacksData {
  ritual_monthly_feedbacks: Array<RitualMonthlyFeedbackRowExternal>;
}

export default Vue.extend({
  name: 'm-ritual-monthly-feedbacks-widget',
  components: { MUserPreview },
  props: {
    value: { type: Object as PropType<RitualMonthlyFeedbacksData> },
    settings: { type: Object, default: () => ({}) },
  },
  data: () => ({
    roleFilter: undefined as MentoringRole,
    selectedRoles: [],
  }),
  computed: {
    headers() {
      const headers = ['month', 'user', 'role'];
      if (!this.settings?.columns) {
        const answers = new Set<string>();
        this.feedbacks.forEach((feedback) => {
          for (const answer in feedback.answers) {
            answers.add(answer);
          }
        });
        headers.push(...answers);
      } else {
        headers.push(...this.settings.columns);
      }

      return headers;
    },
    average() {
      const results = {};
      const counts = {};
      this.feedbacks.forEach((feedback) => {
        for (const answer in feedback.answers) {
          if (!Object.prototype.hasOwnProperty.call(results, answer)) {
            results[answer] = 0;
            counts[answer] = 0;
          }
          results[answer] += Number(feedback.answers[answer]?.rating ?? 0);
          counts[answer] += 1;
        }
      });

      for (const answer in results) {
        results[answer] = (results[answer] /= counts[answer]).toFixed(1);
      }

      return results;
    },
    feedbacks(): Array<RitualMonthlyFeedbackRowInternal> {
      let result =
        this.value?.ritual_monthly_feedbacks?.map((row) => ({
          answers: row.answers,
          attributes: row.attributes,
          role: row.role,
          user: { id: row.userId, identity: { attributes: row.identity } },
          feedbackName: row.feedbackName,
        })) ?? [];
      if (this.selectedRoles?.length > 0) {
        const rolesFilter = [];
        if (this.selectedRoles.includes(0)) {
          rolesFilter.push(MentoringRole.Mentee);
        }
        if (this.selectedRoles.includes(1)) {
          rolesFilter.push(MentoringRole.Mentor);
        }
        result = result.filter((feedback) => rolesFilter.includes(feedback.role));
      }

      return result;
    },
    feedbackNames() {
      const names = new Set();
      this.value?.ritual_monthly_feedbacks?.forEach((row) => {
        names.add(row.feedbackName);
      });
      return Array.from(names);
    },
  },
  methods: {
    download() {
      const header = this.headers
        .map((answer) => {
          if (['month', 'user', 'role', 'change'].includes(answer)) {
            const title = this.$t(`dashboard.widgets.ritual_monthly_feedbacks.table.header.${answer}`);
            return `${title}`;
          } else {
            const questionText = this.$t(
              `dashboard.widgets.ritual_monthly_feedbacks.table.header.${this.getQuestionText(answer)}`,
            ).replace(/"/g, "'");
            return `"${questionText} (Rating)","${questionText} (Comment)"`;
          }
        })
        .join(',');
      const csvContent =
        'data:text/csv;charset=utf-8,' +
        [
          header,
          ...this.feedbacks.map((feedback) => {
            const values = this.headers
              .slice(3)
              .map(
                (answer) =>
                  `"${this.getValue(feedback.answers[answer])}","${this.getComment(feedback.answers[answer])}"`,
              )
              .join(',');
            const role = this.$t(`mentoring.role.${feedback.role}`);
            const change = feedback.answers['change']?.replace(/(\r\n|\n|\r)/gm, ' ').trim() ?? '';
            return `${feedback.attributes.month},${feedback.user.identity.attributes.email},${role},${values},${change}`;
          }),
        ].join('\n');
      const encodedUri = encodeURI(csvContent);
      const link = document.createElement('a');
      link.setAttribute('href', encodedUri);
      link.setAttribute('download', 'monthly_feedbacks.csv');
      document.body.appendChild(link); // Required for FF
      link.click();
    },
    getValue(answer) {
      if (typeof answer === 'object') {
        let a = answer['rating'] ?? '';
        if (this.$i18n.te(`dashboard.widgets.ritual_monthly_feedbacks.table.items.${a}`)) {
          a = this.$t(`dashboard.widgets.ritual_monthly_feedbacks.table.items.${a}`);
        }
        return String(a).replace(/"/g, "'");
      } else {
        if (typeof answer === 'boolean') {
          return answer ? 'Yes' : 'No';
        }
        let b = answer ?? '';
        if (this.$i18n.te(`dashboard.widgets.ritual_monthly_feedbacks.table.items.${b}`)) {
          b = this.$t(`dashboard.widgets.ritual_monthly_feedbacks.table.items.${b}`);
        }
        return b.replace(/"/g, "'");
      }
    },
    getComment(answer) {
      if (typeof answer === 'object') {
        let a = (answer['comment']?.replace(/(\r\n|\n|\r)/gm, ' ').trim() ?? '').replace(/"/g, "'");
        if (this.$i18n.te(`dashboard.widgets.ritual_monthly_feedbacks.table.items.${a}`)) {
          a = this.$t(`dashboard.widgets.ritual_monthly_feedbacks.table.items.${a}`);
        }
        return a;
      } else {
        // check if type is boolean
        if (typeof answer === 'boolean') {
          return answer ? 'Yes' : 'No';
        }
        let b = (answer?.replace(/(\r\n|\n|\r)/gm, ' ').trim() ?? '').replace(/"/g, "'");
        if (this.$i18n.te(`dashboard.widgets.ritual_monthly_feedbacks.table.items.${b}`)) {
          b = this.$t(`dashboard.widgets.ritual_monthly_feedbacks.table.items.${b}`);
        }
        return b;
      }
    },
    getQuestionText(question: string) {
      for (const name of this.feedbackNames) {
        if (this.$te(`questionnaire.${name}.feedback.questions.${question}`)) {
          return this.$t(`questionnaire.${name}.feedback.questions.${question}`);
        }
      }
      return question;
    },
  },
});
</script>
